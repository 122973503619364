var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _vm.data.companionFlag === "Y"
                          ? _c("font", { attrs: { color: "#C10015" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "※ 요청승인 반려사유 : " +
                                      _vm.data.refusalReason
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-equip", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "설비Tag",
                              name: "equipmentCd",
                            },
                            on: { dataChange: _vm.equipDataChange },
                            model: {
                              value: _vm.data.equipmentCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentCd", $$v)
                              },
                              expression: "data.equipmentCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-func-location", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: _vm.editable && !_vm.disabled,
                              label: "기능위치",
                              name: "funcLocationCd",
                            },
                            model: {
                              value: _vm.data.funcLocationCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "funcLocationCd", $$v)
                              },
                              expression: "data.funcLocationCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-cost-center", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: _vm.editable && !_vm.disabled,
                              label: "코스트센터",
                              name: "costCenterCd",
                            },
                            model: {
                              value: _vm.data.costCenterCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "costCenterCd", $$v)
                              },
                              expression: "data.costCenterCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              codeGroupCd: "WO_WORK_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "woWorkTypeCd",
                              label: "작업유형",
                            },
                            model: {
                              value: _vm.data.woWorkTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woWorkTypeCd", $$v)
                              },
                              expression: "data.woWorkTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "작업명",
                              name: "woRequestName",
                            },
                            model: {
                              value: _vm.data.woRequestName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woRequestName", $$v)
                              },
                              expression: "data.woRequestName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              default: "today",
                              label: "요청일",
                              name: "woRequestDt",
                            },
                            model: {
                              value: _vm.data.woRequestDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woRequestDt", $$v)
                              },
                              expression: "data.woRequestDt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              editable: _vm.editable && !_vm.disabled,
                              label: "작업요청 부서",
                              required: true,
                              name: "deptCd",
                            },
                            model: {
                              value: _vm.data.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "deptCd", $$v)
                              },
                              expression: "data.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              data: _vm.data,
                              type: "user",
                              label: "요청인",
                              name: "userId",
                            },
                            model: {
                              value: _vm.data.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "userId", $$v)
                              },
                              expression: "data.userId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable && !_vm.disabled,
                              isFlag: true,
                              label: "긴급작업",
                              name: "emergencyFlag",
                            },
                            model: {
                              value: _vm.data.emergencyFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "emergencyFlag", $$v)
                              },
                              expression: "data.emergencyFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-9" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.data.emergencyFlag == "Y",
                              label: "긴급작업 사유",
                              name: "emergencyReason",
                            },
                            model: {
                              value: _vm.data.emergencyReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "emergencyReason", $$v)
                              },
                              expression: "data.emergencyReason",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && !_vm.disabled,
                              label: "MOC번호",
                              name: "mocId",
                            },
                            model: {
                              value: _vm.data.mocId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "mocId", $$v)
                              },
                              expression: "data.mocId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && !_vm.disabled,
                              label: "MOC 명칭",
                              name: "mocName",
                            },
                            model: {
                              value: _vm.data.mocName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "mocName", $$v)
                              },
                              expression: "data.mocName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable && !_vm.disabled,
                              rows: 7,
                              label: "작업요청 세부사항",
                              name: "remark",
                            },
                            model: {
                              value: _vm.data.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "remark", $$v)
                              },
                              expression: "data.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "c-table",
                            {
                              ref: "grid",
                              attrs: {
                                title: "정비오더 발행부서",
                                isTitle: true,
                                columns: _vm.grid.columns,
                                data: _vm.data.deptList,
                                gridHeight: _vm.grid.height,
                                editable: _vm.editable && !_vm.disabled,
                                hideBottom: true,
                                isExcelDown: false,
                                filtering: false,
                                isFullScreen: false,
                                columnSetting: false,
                                selection: "multiple",
                                rowKey: "deptCd",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "table-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _vm.editable &&
                                      !_vm.disabled &&
                                      _vm.data.deptList.length < 1
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "추가",
                                              icon: "add",
                                            },
                                            on: { btnClicked: _vm.addRow },
                                          })
                                        : _vm._e(),
                                      _vm.editable && !_vm.disabled
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "삭제",
                                              icon: "remove",
                                            },
                                            on: { btnClicked: _vm.removeRow },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-12",
                staticStyle: { "padding-top": "25px !important" },
              },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable && !_vm.disabled,
                    label: "첨부파일",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-appr-btn", {
                  ref: "appr-btn",
                  attrs: {
                    name: "equipment-daily-appr-btn",
                    editable: _vm.editable,
                    approvalInfo: _vm.approvalInfo,
                  },
                  on: {
                    beforeApprAction: _vm.saveDataAppr,
                    callbackApprAction: _vm.approvalCallback,
                    requestAfterAction: _vm.getDetail,
                  },
                }),
                !_vm.popupParam.woRequestId && _vm.editable
                  ? _c("c-btn", {
                      attrs: {
                        label: "기존 작업요청 불러오기",
                        icon: "upload_file",
                      },
                      on: { btnClicked: _vm.copyData },
                    })
                  : _vm._e(),
                _vm.popupParam.woRequestId && !_vm.removeDisabled
                  ? _c("c-btn", {
                      attrs: { label: "삭제", icon: "delete_forever" },
                      on: { btnClicked: _vm.removeData },
                    })
                  : _vm._e(),
                _vm.editable && !_vm.disabled
                  ? _c("c-btn", {
                      attrs: {
                        url: _vm.saveUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.data,
                        mappingType: _vm.mappingType,
                        label: "저장",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveData,
                        btnCallback: _vm.saveCallback,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }